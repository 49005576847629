import FullWidthContainer from '@components/layout/full-width-container'
import { Button } from '@components/ui/button'
import image from './get-started-section-image.webp'
import highlightWordIcon from './highlight-word-icon.svg'
import { getProcessedImage, type ProcessedImages } from '@lib/image'
import { ResponsiveImage } from '@components/ui/responsive-image'

export const GetStartedSection = ({
  openGetStarted,
  processedImages
}: {
  openGetStarted: (stateId?: string) => void
  processedImages: ProcessedImages
}) => {
  return (
    <section className="bg-blue-50 pt-[10px] pb-[100px] desktop:pt-[80px]">
      <FullWidthContainer>
        <p className="text-body-main-bold mb-[20px]">A good place to start:</p>
        <div className="flex items-start justify-between">
          <div>
            <h2 className="text-title-xl desktop:text-title-xl-desktop mb-[40px] small-desktop:mb-[60px] relative max-w-[700px]">
              See the best 529 plans, personalized for you
              <img
                src={highlightWordIcon.src}
                alt=""
                className="absolute left-[236px] min-[383px]:left-[318px] top-[-33px] desktop:left-[480px] desktop:top-[-30px]"
              />
            </h2>
            <Button
              id="getStartedButtonOther"
              className="w-full max-w-[335px] small-desktop:max-w-[260px]"
              size="lg"
              onClick={() => openGetStarted()}
            >
              Get started
            </Button>
          </div>
          <ResponsiveImage
            image={getProcessedImage(processedImages, image)}
            width={375}
            height={309}
            alt=""
            className="hidden small-desktop:block self-center"
          />
        </div>
      </FullWidthContainer>
    </section>
  )
}
