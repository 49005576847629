import FullWidthContainer from '@components/layout/full-width-container'
import { Button } from '@components/ui/button'
import HighlightRightIcon from '@components/ui/icons/highlight-right-icon'
import josephHurley from './joseph-hurley.webp'
import marcSuhr from './marc-shur.webp'
import ebookCoverSvg from './ebook-cover.svg'
import ebookCoverImg from './ebook-cover-image.webp'
import { useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle
} from '@components/ui/dialog'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@components/ui/form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { US_STATES } from '@lib/us-states'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@components/ui/select'
import { Input } from '@components/ui/input'
import { runQuery } from '@lib/graphql'
import { Loader2 } from 'lucide-react'
import { trackFlowEnded, trackFlowStarted } from '@lib/analytics'
import { getProcessedImage, type ProcessedImages } from '@lib/image'
import { ResponsiveImage } from '@components/ui/responsive-image'

const FAMILY_GUIDE_URL =
  'https://web-resources.savingforcollege.com/guides/family_guide_2024_2025.pdf'

const ANALYTICS_FLOW_NAME = 'DownloadEbook'

interface AuthorInfoProps {
  name: string
  role: string
  image: ImageMetadata
  processedImages: ProcessedImages
}

const AuthorInfo = ({ name, role, image, processedImages }: AuthorInfoProps) => (
  <div className="flex gap-x-[15px]">
    <ResponsiveImage
      image={getProcessedImage(processedImages, image)}
      alt={`${name}'s profile`}
      width={50}
      height={50}
      className="shrink-0 h-[50px] w-[50px] rounded-full"
    />
    <div className="flex flex-col">
      <div className="text-body-main-bold">{name}</div>
      <div className="text-body-caption">{role}</div>
    </div>
  </div>
)

const DownloadForm = ({ onDownloadStarted }: { onDownloadStarted: () => void }) => {
  const formSchema = z.object({
    userType: z.string({
      message: 'Please select your role'
    }),
    state: z.string({
      message: 'Please select your state of residence'
    }),
    email: z.string().email()
  })

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      userType: '',
      state: '',
      email: ''
    }
  })

  const [isLoading, setIsLoading] = useState(false)

  const onSubmit = (values: z.infer<typeof formSchema>) => {
    sendData(values)
  }

  const sendData = async (data: z.infer<typeof formSchema>) => {
    const query = `
				mutation {
					track_data(user_type: "${data.userType}", state_id: "${data.state}", email: "${data.email}", email_source: "Homepage PC 529 Download")
				}
				`
    try {
      setIsLoading(true)
      await runQuery(query)
    } catch (e) {
      console.error('Failed to send data', e)
    }

    onDownloadStarted()
    setTimeout(() => {
      window.open(FAMILY_GUIDE_URL, '_blank')
    }, 500)
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-[10px]">
        <FormField
          control={form.control}
          name="userType"
          render={({ field }) => (
            <FormItem>
              <FormLabel>What better describes you?</FormLabel>
              <Select onValueChange={field.onChange} defaultValue="">
                <FormControl>
                  <SelectTrigger autoFocus>
                    <SelectValue placeholder="Please select your role..." />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value="Parent">Parent</SelectItem>
                  <SelectItem value="Grandparent">Grandparent</SelectItem>
                  <SelectItem value="Financial Advisor">Financial Advisor</SelectItem>
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="state"
          render={({ field }) => (
            <FormItem>
              <FormLabel>State of residence</FormLabel>
              <Select onValueChange={field.onChange} defaultValue="">
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Please select your state..." />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {US_STATES.map((state) => (
                    <SelectItem key={state.abbr} value={state.abbr}>
                      {state.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <Input {...field} type="email" placeholder="Your Email" className="" />
              <FormMessage />
            </FormItem>
          )}
        />
        <div>
          <Button type="submit" size="lg" className="mt-[30px] w-full" disabled={isLoading}>
            {isLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}Download
          </Button>
        </div>
      </form>
    </Form>
  )
}

export const EbookSection = ({ processedImages }: { processedImages: ProcessedImages }) => {
  const [downloadDialogOpen, setDownloadDialogOpen] = useState(false)

  const handleDownload = () => {
    setDownloadDialogOpen(true)
    trackFlowStarted({ flowName: ANALYTICS_FLOW_NAME })
  }

  const onOpenChange = (isOpen: boolean) => {
    setDownloadDialogOpen(isOpen)

    if (!isOpen) {
      trackFlowEnded({ flowName: ANALYTICS_FLOW_NAME, lastStep: '', isCompleted: false })
    }
  }

  const onDownloadStarted = () => {
    setDownloadDialogOpen(false)
    trackFlowEnded({ flowName: ANALYTICS_FLOW_NAME, lastStep: '', isCompleted: true })
  }

  return (
    <section className="bg-blue-50 py-[100px]">
      <FullWidthContainer>
        <div className="flex justify-between gap-x-[60px]">
          <div className="flex flex-col w-full small-desktop:max-w-[550px]">
            <h2 className="text-title-lg small-desktop:text-title-lg-desktop mb-[5px]">
              Family Guide to College Savings
            </h2>
            <div className="flex items-center mb-[40px]">
              <span className="text-body-lead mr-[15px]">2024 — 2025 Edition</span>
              <span className="text-body-caption-bold justify-center mr-[10px] px-[10px] py-[4px] bg-white-500 rounded-lg">
                PDF
              </span>
              <span className="text-blue-500 h-[27px] small-desktop:h-[35px]">
                <HighlightRightIcon />
              </span>
            </div>
            <Button
              id="ebookCoverButton"
              variant="content"
              size="content"
              className="small-desktop:hidden mb-[20px] w-[335px] ml-[-14px]"
              onClick={handleDownload}
              aria-label="Download free E-book"
            >
              <EbookCover processedImages={processedImages} />
            </Button>
            <p className="w-full mb-[15px]">
              This guide is a must-read for all parents with college costs in their future. Whether
              you have toddlers or teenagers, this ebook will help you develop a simple and
              cost-effective strategy for saving and paying for college.
            </p>
            <div className="hidden small-desktop:flex mb-[35px] gap-[30px]">
              <AuthorInfo
                name="Joseph F. Hurley, CPA"
                role="Author"
                image={josephHurley}
                processedImages={processedImages}
              />
              <AuthorInfo
                name="Marc E. Suhr II"
                role="Editor"
                image={marcSuhr}
                processedImages={processedImages}
              />
            </div>
            <Button className="justify-center self-start" onClick={handleDownload}>
              Download free E-book
            </Button>
          </div>
          <Button
            id="ebookDownloadButton"
            variant="content"
            size="content"
            className="hidden small-desktop:block w-[370px]"
            onClick={handleDownload}
            aria-label="Download free E-book"
          >
            <EbookCover processedImages={processedImages} />
          </Button>
        </div>
      </FullWidthContainer>
      <Dialog open={downloadDialogOpen} onOpenChange={onOpenChange}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>
              <span>
                <span>Family Guide to College Savings</span>
                <span className="inline-block align-middle ml-[12px]">
                  <div className="flex px-[10px] py-[4px] bg-blue-100 rounded-lg h-[30px]">
                    <span className="text-body-caption">PDF</span>
                  </div>
                </span>
              </span>
            </DialogTitle>
            <DialogDescription>
              Please provide the information bellow to qualify for free download.
            </DialogDescription>
          </DialogHeader>
          <div className="pointer-events-auto">
            <DownloadForm onDownloadStarted={onDownloadStarted} />
          </div>
        </DialogContent>
      </Dialog>
    </section>
  )
}

const EbookCover = ({ processedImages }: { processedImages: ProcessedImages }) => {
  return (
    <div className="relative w-[335px] h-[457px] small-desktop:w-[370px] small-desktop:h-[490px]">
      <img src={ebookCoverSvg.src} alt="" />
      <ResponsiveImage
        image={getProcessedImage(processedImages, ebookCoverImg)}
        width={300}
        height={172}
        alt=""
        className="absolute left-[9%] top-[22%] w-[75%]"
      />
    </div>
  )
}
