import FullWidthContainer from '@components/layout/full-width-container'
import SidebarAds from '@components/layout/sidebar/sidebar-ads'
import { Link } from '@components/ui/link'
import howToSaveForCollege from './how-to-save-for-college.webp'
import whatIsA529Plan from './what-is-a-529-plan.webp'
import grandparentsAnd529Plans from './grandparents-and-529-plans.webp'
import howToChooseA529Plan from './how-to-choose-a-529-plan.webp'
import { getProcessedImage, type ProcessedImages } from '@lib/image'
import { ResponsiveImage } from '@components/ui/responsive-image'

export const ArticlesSection = ({ processedImages }: { processedImages: ProcessedImages }) => {
  return (
    <section className="flex flex-col content-stretch justify-items-stretch small-desktop:pt-[80px] pb-[100px] desktop:px-[100px]">
      <FullWidthContainer>
        <h2 className="text-title-md small-desktop:text-title-lg-desktop pb-[40px] desktop:text-center desktop:pb-[70px]">
          Articles and guides
        </h2>
      </FullWidthContainer>
      <FullWidthContainer mobileNoPadding={true} sidebar={<SidebarAds isCompact={true} />}>
        <ul className="grid grid-cols-1 desktop:grid-cols-2 desktop:gap-x-[68px] gap-y-[70px]">
          {articles.map((article, idx) => (
            <li key={idx}>
              <Article processedImages={processedImages} {...article} />
            </li>
          ))}
        </ul>
      </FullWidthContainer>
    </section>
  )
}

const Article = ({
  title,
  mainLink,
  image,
  description,
  items,
  processedImages
}: {
  title: string
  mainLink: string
  image: ImageMetadata
  description: string
  items: {
    title: string
    link: string
  }[]
  processedImages: ProcessedImages
}) => {
  return (
    <div className="flex flex-col max-w-[400px] p-[0_20px] min-[696px]:p-0">
      <Link variant="black" href={mainLink} className="mb-[30px]">
        <div className="relative w-full aspect-[334/193] mb-[20px] desktop:w-[321px] desktop:h-[229px] desktop:aspect-auto transition ease-in-out duration-300 group-hover:scale-103">
          <ResponsiveImage
            image={getProcessedImage(processedImages, image)}
            width={322}
            height={230}
            alt=""
            className="absolute inset-0 w-full h-full object-cover rounded-[10px] desktop:static desktop:object-contain"
          />
        </div>
        <h2 className="text-title-sm mb-[10px]">{title}</h2>
        <p className="text-body-main mb-[15px]">{description}</p>
        <div className="text-body-caption-bold text-blue-500">Read more →</div>
      </Link>
      <ul className="flex flex-col gap-[10px]">
        {items.map((item, idx) => (
          <li key={idx} className="flex gap-[20px] items-center">
            <span className="text-body-caption-bold text-blue-500">0{idx + 1}.</span>
            <Link href={item.link} className="text-body-main-bold" variant="inline">
              {item.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

const articles = [
  {
    title: 'How to save for college?',
    mainLink: 'https://www.savingforcollege.com/compare_savings_options/',
    image: howToSaveForCollege,
    description: 'See how 529 plans compare to other types of savings accounts.',
    items: [
      {
        title: '529 plans vs. Roth IRAs',
        link: 'https://www.savingforcollege.com/articles/which-is-best-529-college-savings-plan-or-roth-ira'
      },
      {
        title: 'Understand Coverdell ESAs',
        link: 'https://www.savingforcollege.com/coverdell_esas/'
      },
      {
        title: '6 ways to save for college',
        link: 'https://www.savingforcollege.com/article/6-ways-you-can-save-for-college'
      }
    ]
  },
  {
    title: 'What is a 529 plan?',
    mainLink: 'https://www.savingforcollege.com/intro-to-529s/what-is-a-529-plan',
    image: whatIsA529Plan,
    description: '529 plans are the best way to save for college.',
    items: [
      {
        title: 'How much can I contribute?',
        link: 'https://www.savingforcollege.com/article/how-much-can-you-contribute-to-a-529-plan'
      },
      {
        title: 'What can I pay for with a 529?',
        link: 'https://www.savingforcollege.com/article/what-you-can-pay-for-with-a-529-plan'
      },
      {
        title: '529 plans and financial aid',
        link: 'https://www.savingforcollege.com/intro-to-529s/does-a-529-plan-affect-financial-aid'
      }
    ]
  },
  {
    title: 'Grandparents & 529 plans',
    mainLink: 'https://www.savingforcollege.com/grandparents/',
    image: grandparentsAnd529Plans,
    description: 'With 529 plans, grandparents can leave a legacy and enjoy unique benefits.',
    items: [
      {
        title: 'Grandparent vs. parent 529',
        link: 'https://www.savingforcollege.com/article/can-a-grandparent-contribute-to-a-parent-owned-529-plan'
      },
      {
        title: '13 ways grandparents can help',
        link: 'https://www.savingforcollege.com/article/10-easy-ways-grandparents-can-help-pay-for-college'
      },
      {
        title: 'Financial aid impact',
        link: 'https://www.savingforcollege.com/article/new-fafsa-removes-roadblocks-for-grandparent-529-plans'
      }
    ]
  },
  {
    title: 'How to choose a 529 plan',
    mainLink: 'https://www.savingforcollege.com/article/how-to-choose-the-best-529-plan-for-you',
    image: howToChooseA529Plan,
    description: 'Four steps to choosing the right 529 plan for your family.',
    items: [
      {
        title: "Should I open my state's plan?",
        link: 'https://www.savingforcollege.com/article/how-much-is-your-state-s-529-plan-tax-deduction-really-worth'
      },
      {
        title: 'Compare 529 plans',
        link: 'https://www.savingforcollege.com/compare-college-savings-plans/#/step0'
      },
      {
        title: 'Best 529 plans',
        link: 'https://www.savingforcollege.com/intro-to-529s/which-is-the-best-529-plan-available'
      }
    ]
  }
]
