import { HeroSection } from './hero/hero-section'
import { ArticlesSection } from './articles/articles-section'
import { EbookSection } from './ebook/ebook-section'
import { FeaturedPlansSection } from './featured-plans/featured-plans-section'
import { ToolsSection } from './tools/tools-section'
import { Separator } from '@components/ui/separator'
import type { FeaturedPlans } from './featured-plans/featured-plans-model'
import { GetStartedSection } from './get-started/get-started-section'
import { useEffect, useRef } from 'react'
import { CommonDialogs } from '@components/layout/common-dialogs'
import type { GetStartedDialogHandle } from '@modules/get-started/get-started-dialog'
import type { ProcessedImages } from '@lib/image'

interface Props {
  featuredPlansData: FeaturedPlans
  processedImages: ProcessedImages
}

export const HomePage = ({ featuredPlansData, processedImages }: Props) => {
  const getStartedDialogRef = useRef<GetStartedDialogHandle>(null)

  const openGetStarted = (stateId?: string) => {
    getStartedDialogRef.current?.openGetStartedOrSavingsCenter(stateId)
  }

  const openGetStartedIfQuery = () => {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get('savings-center') === 'true') {
      openGetStarted()
    }
  }

  useEffect(() => {
    openGetStartedIfQuery()
  }, [])

  return (
    <div className="flex flex-col">
      <HeroSection openGetStarted={openGetStarted} />
      <Separator strokeWidth={1} className="hidden small-desktop:block bg-black-50" />
      <ArticlesSection processedImages={processedImages} />
      <EbookSection processedImages={processedImages} />
      <FeaturedPlansSection data={featuredPlansData} />
      <ToolsSection />
      <GetStartedSection openGetStarted={openGetStarted} processedImages={processedImages} />
      <CommonDialogs getStartedDialogRef={getStartedDialogRef} />
    </div>
  )
}
